import Vue from 'vue';
import { countriesDialCodes } from '@/enum/countryDialCodes';

/**
 * All Country list
*/
export const countries = ['DE','AT','NL','FR','AF','AX','AL','DZ','AS','AD','AO','AI','AQ','AG','AR','AM','AW','AU','AZ','BS','BH','BD','BB','BY','BE','BZ','BJ','BM','BT','BO','BA','BW','BV','BR','IO','BN','BG','BF','BI','KH','CM','CA','CV','KY','CF','TD','CL','CN','CX','CC','CO','KM','CG','CD','CK','CR','CI','HR','CU','CY','CZ','DK','DJ','DM','DO','EC','EG','SV','GQ','ER','EE','ET','FK','FO','FJ','FI','GF','PF','TF','GA','GM','GE','GH','GI','GR','GL','GD','GP','GU','GT','GG','GN','GW','GY','HT','HM','VA','HN','HK','HU','IS','IN','ID','IR','IQ','IE','IM','IL','IT','JM','JP','JE','JO','KZ','KE','KI','KR','KW','KG','LA','LV','LB','LS','LR','LY','LI','LT','LU','MO','MK','MG','MW','MY','MV','ML','MT','MH','MQ','MR','MU','YT','MX','FM','MD','MC','MN','ME','MS','MA','MZ','MM','NA','NR','NP','AN','NC','NZ','NI','NE','NG','NU','NF','MP','NO','OM','PK','PW','PS','PA','PG','PY','PE','PH','PN','PL','PT','PR','QA','RE','RO','RU','RW','BL','SH','KN','LC','MF','PM','VC','WS','SM','ST','SA','SN','RS','SC','SL','SG','SK','SI','SB','SO','ZA','GS','ES','LK','SD','SR','SJ','SZ','SE','CH','SY','TW','TJ','TZ','TH','TL','TG','TK','TO','TT','TN','TR','TM','TC','TV','UG','UA','AE','GB','US','UM','UY','UZ','VU','VE','VN','VG','VI','WF','EH','YE','ZM','ZW'];

/**
 * Blacklisted countries list tax residency
*/
export const blacklistedCountries = ['AF', 'AL', 'BS', 'BB', 'BM', 'BW', 'CN', 'CY', 'GG', 'GH', 'IM', 'IQ', 'IR', 'JE', 'JM', 'KY', 'KH', 'KP', 'MT', 'MM', 'MN', 'MU', 'NI', 'PA', 'PK', 'RU', 'SY', 'TR', 'TT', 'UG', 'VG', 'VU', 'YE', 'ZW'];

export const nationalityOptions = () => countries.map(code =>({
        code,
        country: Vue.prototype.$t('core:iso3166.nationality.'+ code),
      }))
      .sort((a, b) => (a.country > b.country) ? 1 : -1);

export const countryOptions = () => 
  countries.map(code =>({
    code,
    country: Vue.prototype.$t('core:iso3166.country.'+ code),
  }))
  .sort((a, b) => (a.country > b.country) ? 1 : -1);

export const countryPhoneCodes = countryOptions().map(country => {
  const countryCode = countriesDialCodes.find(dialCode => country.code.toLowerCase() === dialCode.code.toLowerCase());
  
  if(!!countryCode) {
    return {
      ...countryCode,
      name: country.country,
    };
  };

  return null;
}).filter( result => result !== null && result !== undefined);

export default countries;

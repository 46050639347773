































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'AutoCompleteComponent',
})
export default class AutoCompleteComponent extends Vue {
  @Prop({ default: false, required: false }) private disabled?: Boolean;
  @Prop({ default: false, required: false }) private readonly?: Boolean;
  @Prop({ default: true, required: false }) clearable?: Boolean;
  @Prop({ default: false, required: false }) filled?: Boolean;
  @Prop({ default: '', required: false }) private error?: string;
  @Prop({ default: '', required: false }) label?: string;
  @Prop({ default: () => {}, required: true }) private nextBtnClicked?: Function;
  @Prop({ default: [], required: true }) private optionsList?: string[];
  @Prop({ default: () => {} }) private onSelect?: Function;
  @Prop({ default: '', required: false }) private tooltip?: string;
  @Prop({ default: '', required: false }) private value?: string;
  @Prop({ default: '', required: false }) private valueKey?: string;
  @Prop({ default: '', required: false }) private valueLabel?: string;
  @Prop({ default: '', required: false }) private placeholder?: string;

  get isError() {
    return (!!this.value && !!this.error) || (!!this.nextBtnClicked && !!this.error);
  }

  public onSave(value) {
    this.$emit('input', value);
    if (this.onSelect) {
      this.onSelect();
    }
  }
}

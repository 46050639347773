<template>
  <div>
    <div class="input-title-wrapper">
      <v-tooltip v-if="!!tooltip" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="input-title-wrapper__tooltip" absolute color="grey" dark icon v-bind="attrs" v-on="on">
            <v-icon>info</v-icon>
          </v-btn>
        </template>
        <span v-html="tooltip">{{ tooltip }}</span>
      </v-tooltip>
    </div>
    <div class="input-section" :class="{ 'text--error': !!error }">
      <div v-if="!filled" class="subtitle-2 text--primary" :class="filled ? '' :  'mb-2'">{{ label }}</div>
      <!-- date picker -->
      <template v-if="inputType === 'date'">
        <v-text-field
          v-mask="'##/##/####'"
          :error="!!error"
          :value="computeDateFormatted()"
          @input="onDateType"
          @blur="onSave"
          :placeholder="placeholder"
          :outlined="!readonly && !filled"
          :readonly="readonly"
          :dense="readonly"
          :solo="readonly"
          :flat="readonly"
          :disabled="!!readonly ? false : disabled"
          :class="{ readonly: readonly }"
          :id="id()"
          hide-details="auto"
          :rules="[!isError()]"
          :messages="[error]"
          type="text"
          :filled="filled"
          :label="filled && value ? label : null"
        ></v-text-field>
      </template>
      <!-- regular input -->
      <v-text-field
        v-else
        :error="!!error && isBlur"
        :outlined="!readonly && !filled"
        :readonly="readonly"
        :dense="readonly"
        :solo="readonly"
        :flat="readonly"
        :disabled="!!readonly ? false : disabled"
        :class="{ readonly: readonly }"
        :id="id()"
        :type="inputType"
        :value="value"
        @input="(value) => $emit('input', value)"
        @blur="onSave"
        :placeholder="placeholder"
        hide-details="auto"
        :rules="[!isError()]"
        :messages="[error]"
        :filled="filled"
        :label="filled && value ? label : null"
      ></v-text-field>
    </div>
  </div>
</template>

<script>

export default {
  name:'InputComponent',
  data() {
    return {
      insertedValue: '',
      isBlur: false,
    };
  },
  props: [
    'label',
    'placeholder',
    'error',
    'tooltip',
    'onChange',
    'inputType',
    'value',
    'nextBtnClicked',
    'disabled',
    'readonly',
    'filled',
  ],
  methods: {
    onSave() {
      this.insertedValue = this.value;
      this.isBlur = true;

      if (this.onChange) {
        this.onChange();
      }
    },
    onPickerSelect(value) {
      this.$emit('input', value);
      this.insertedValue = value;
      this.onSave();
      this.isPickerOpen = false;
    },
    onDateType(value) {
      // takes a date in dd/mm/yyyy format and converts it to yyyy-mm-dd
      let [day, month, year] = value.split('/');
      const reversedDate = value.split('/').reverse().join('-');
      this.insertedValue = reversedDate;
      this.$emit('input', reversedDate);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');

      return `${day || 'dd'}/${month || 'mm'}/${year || 'yyyy'}`;
    },
    computeDateFormatted() {
      // mask directive takes over while input value has less than 6 characters
      return this.value && this.value.length > 6 ? this.formatDate(this.value) : '';
    },
    isError() {
      return (!!this.insertedValue && !!this.error) || (!!this.nextBtnClicked && !!this.error);
    },
    id() {
      return this.label
        ? this.label
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ +/g, '-')
        : '';
    },
  },
};
</script>

<style lang="scss">
.input-title-wrapper {
  position: relative;
  &__tooltip {
    top: -8px;
    right: 8px;
  }
}
.input-section {
  .readonly {
    cursor: default;
    pointer-events: none;
    .v-input__slot {
      padding: 0 !important;
    }
    .v-input__icon {
      opacity: 0;
    }
  }
}
</style>

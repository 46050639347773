


































































































import {TBrandItem} from '@/utils/brand-schemas';
import {getBrandSchema} from '@/utils/helpers';
import brandNames from '@/enum/brands';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class BrandedButton extends Vue {
  @Prop({ default: null }) title!: string | null
  @Prop({ default: 'accent' }) color!: string
  @Prop({ default: 'primary' }) type!: string
  @Prop({ default: null }) iconLeft!: string | null
  @Prop({ default: null }) iconRight!: string | null
  @Prop({ default: null }) href!: string | null
  @Prop({ default: '_self' }) target!: string
  @Prop({ default: false, type: Boolean }) small!: boolean
  @Prop({ default: false, type: Boolean }) disabled!: boolean
  @Prop({ default: false, type: Boolean }) loading!: boolean
  @Prop({ default: false, type: Boolean  }) inline!: boolean
  @Prop({ default: false, type: Boolean  }) tile!: boolean
  @Prop({ default: false, type: Boolean  }) text!: boolean
  @Prop({ default: false, type: Boolean  }) block!: boolean
  @Prop({ default: '' }) onClick!: () => void

  private brandName: string = 'Exporo';

  get isPropvest() {
    return this.brandName === brandNames.PROPVEST;
  }

  getVuetifyedIcon(icon) {
    return icon.includes('-') ? icon.replace('-', '_') : icon;
  }

  get brandedTitle() {
    return this.title;
  }

  getIconColorByType() {
    return this.color === 'primary' ? 'primary' : 'white';
  }

  mounted() {
    const schema: TBrandItem = getBrandSchema();
    this.brandName = schema.name;
  }
};

<template>
  <div class="edit-component">
    <slot
      :naturalUser="naturalUser"
      :checkIfExists="checkIfExists"
      :onSave="onSave"
      :onCancel="onCancel"
      :niceErrorMessage="niceErrorMessage"
      :errors="errors"
      :nextBtnClicked="nextBtnClicked"
      :loading="loading"
    />
    <v-row v-if="(!!backLabel && onBack) || (!!nextLabel && onNext)" class="edit-component__control ma-0">
      <BrandedButton
        v-if="!!backLabel && onBack"
        type="tertiary"
        color="primary"
        text
        :title="backLabel"
        :disabled="loading"
        :small="true"
        :onClick="onBack"
        icon-left="chevron-left"
      />
      <v-spacer v-if="!onBack || $vuetify.breakpoint.smAndUp" />
      <BrandedButton
        v-if="!!nextLabel && onNext"
        :loading="loading && nextBtnClicked"
        :title="nextLabel"
        color="accent"
        icon-right="chevron-right"
        :small="true"
        :onClick="onNextBtnClicked"
      />
    </v-row>
  </div>
</template>

<script>
import hash from 'object-hash';
import * as _ from 'lodash';
import BrandedButton from '@/components/reusable/BrandedButton';

export default {
  name: 'EditComponent',
  components: {
    BrandedButton,
  },
  data() {
    return {
      loading: false,
      lastStorePayloadHash: null,
      remainingErrors: [],
      errors: {},
      naturalUser: {
        address: {},
        birth: {},
        legitimationDocument: {},
        job: {},
        title: '',
        sex: '',
        isUSTaxed: false,
        isPEP: false,
      },
      nextBtnClicked: false,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    limitedFields: {
      type: String,
      default: '',
    },
    backLabel: {
      type: String,
      default: '',
    },
    nextLabel: {
      type: String,
      default: '',
    },
    onBack: {
      type: Function,
      default: () => false,
    },
    onNext: {
      type: Function,
      default: () => false,
    },
    stateCallback: {
      type: Function,
      default: (state, value) => {
        return {
          state,
          value,
        };
      },
    },
  },
  methods: {
    checkIfExists: function (string) {
      return _.get(this, string);
    },
    onSave: function () {
      this.errors = [];
      this.remainingErrors = [];

      if (this.lastStorePayloadHash === hash(this.naturalUser)) {
        return console.log('Prevent the same payload from being pushed to the backend.');
      }

      this.loading = true;
      this.$emit('loading-update', this.loading);
      this.stateCallback(this.loading, this.remainingErrors);

      return this.$store
        .dispatch('updateNaturalUser', {
          limitedFields: this.limitedFields,
          type: this.$store.getters.validationType,
          formData: {
            type: 'natural',
            naturalUser: this.naturalUser,
          },
        })
        .then(async () => {
          this.errors = [];
          this.remainingErrors = [];
          this.lastStorePayloadHash = hash(this.naturalUser);
          this.$store.commit('setNaturalUser', this.naturalUser);
          this.nextBtnClicked = false;

          return this.$store.dispatch('fetchOnboardingStatus');
        })
        .catch((error) => {
          this.errors = [];
          this.remainingErrors = [];

          if(error === 'unautenticated' || !error.response) {
            this.resumeAppSession();
          }

          error.response.data.forEach((item) => {
            _.set(this.errors, item.path, item);
            this.remainingErrors.push(item.message);
          });
          this.stateCallback(this.loading, this.remainingErrors);

          return false;
        })
        .finally(() => {
          this.loading = false;

          this.stateCallback(this.loading, this.remainingErrors);
          this.$emit('loading-update', this.loading);
          this.$emit('error-update', this.remainingErrors);
        });
    },
    onCancel: function () {
      this.errors = [];
      this.remainingErrors = [];

      if (this.lastStorePayloadHash === hash(this.naturalUser)) {
        return console.log('Prevent the same payload from being pushed to the backend.');
      }

      this.loading = true;
      this.$emit('loading-update', this.loading);
      this.stateCallback(this.loading, this.remainingErrors);
      
      return this.$store
        .dispatch('fetchNaturalUser')
        .then((req) => {
          this.$store.commit('setNaturalUser', req);
          this.naturalUser = req;
        })
        .catch((error) => {
          this.errors = [];
          this.remainingErrors = [];

          if(error === 'unautenticated' || !error.response) {
            this.resumeAppSession();
          }

          error.response.data.forEach((item) => {
            _.set(this.errors, item.path, item);
            this.remainingErrors.push(item.message);
          });
          this.loading = false;

          return false;
        })
        .finally(() => {
          this.loading = false;
          this.stateCallback(this.loading, this.remainingErrors);
          this.$emit('loading-update', this.loading);
          this.$emit('error-update', this.remainingErrors);
        });
    },
    onNextBtnClicked: async function () {
      this.nextBtnClicked = true;

      await this.onSave();

      if (this.remainingErrors.length < 1) {
        this.onNext();
      }
    },
    niceErrorMessage: function (error) {
      const i18nKey = 'metaData.fields.' + error.path.join('.') + '.error.default';

      if (this.$t(i18nKey) !== i18nKey) {
        console.log(this.$t(i18nKey));

        return this.$t(i18nKey);
      }

      return this.$t('metaData.fields.error.default');
    },
    resumeAppSession() {
      if(localStorage.getItem('resumeAppUrl')) {
        const route = window.location.href;
        const resumeRoute = window.location.href;
        window.location.href = localStorage.getItem('resumeAppUrl');
        window.location.reload();
      }
    },
  },
  mounted() {
    this.naturalUser = { ...this.naturalUser, ...this.$store.getters['naturalUser'] };
  },
  computed: {
    baseDomain: () => {
      return process.env.VUE_APP_BACKEND_BASE_DOMAIN;
    },
    kebabTitle() {
      return _.kebabCase(this.title);
    },
    canCollapse() {
      return this.$route.path.includes('profile');
    },
  },
};
</script>

<style scoped lang="scss">
.edit-component {
  &__control {
    align-items: center;
    justify-content: space-between;
  }
}
</style>
